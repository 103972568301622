import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Header } from "../core"
import statementSummary from "../../assets/images/v3/statementSummary.svg"
import statementSummaryDateFilter from "../../assets/images/v3/summaryStatementDateFilter.svg"
import "./styles.css"
import ArrowDown from "../svg/arrowDown"
import rewardPointIcon from "../../assets/images/v3/rewardPointIcon.svg"
import ArrowRight from "../svg/arrowRight"
import cashbackIcon from "../../assets/images/v3/cashbackIcon.svg"
import { BottomSheet } from "react-spring-bottom-sheet"
import Cross from "../svg/v3/Cross"
import CheckIcon from "../svg/v3/checkIcon"
import { ColorButton } from "../core/buttons"
import { useSelector } from "react-redux"
import AccountService from "../../services/AccountService"
import { formatAmount, getFormatedBillingCycle } from "../../utils/functions"
import download from "../../assets/images/v3/downloadStatement.svg"
import DownloadStatement from "./downloadStatement"
import coins from "../../assets/images/v3/coins.svg"
import StatementTransaction_v3 from "../core/StatementTransaction_v3"
import { useNavigate } from "react-router-dom"
import arrowRight from "../../assets/images/v3/arrowRightV3.svg"
import moment from "moment"
import newUserStatementV3 from "../../assets/images/v3/newUserStatementV3.svg"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import { RepaymentTypes } from "../../utils/enums"
import PaymentGatewayV3 from "../Home/PaymentGateway/v3/paymentGatewayV3"
import emptyStatementBg from "../../assets/images/v3/statementEmptyBg.svg"

const StatementSummary = () => {
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const screen = useSelector(state => state.screen)
  const [currentStatement, setCurrentStatement] = useState(
    user.summary.lastStatement,
  )
  const [isDateBottomSheetOpen, setIsDateBottomSheetOpen] = useState(false)
  const [
    isDownloadStatementBottomSheetOpen,
    setDownloadStatementBottomSheetOpen,
  ] = useState(false)
  const { t } = useTranslation()
  const [isCleared, setIsCleared] = useState(false)
  const [isOverDue, setIsOverDue] = useState(false)
  const [isPartiallyPaid, setIsPartiallyPaid] = useState(false)
  const [dueDate, setDueDate] = useState()
  const [cycles, setCycles] = useState([])
  const [selectedCycle, setSelectedCycle] = useState()
  const [filteredCycle, setFilteredCycle] = useState() //contains the cycle after the apply filter button is clicked
  const [isHighlightSectionOpen, setIsHighlightSectionOpen] = useState(false)
  const [isPastCycle, setIsPastCycle] = useState(false)
  const [statements, setStatements] = useState()
  const [transaction, setTransactions] = useState([])
  const [isPaymentGatewayOpen, setIsPaymentGatewayOpen] = useState(false)
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const [isClearedInCurrentCycle, setIsClearedInCurrentCycle] = useState(false)

  const isANewUser = Object.keys(user.summary.lastStatement).length === 0 //check the last statement if empty then he is a new user

  const isRepaymentFlowEnabled = session.repaymentFlow

  //init call for summary api where init data are fetched
  useEffect(() => {
    if (
      !user ||
      !user.summary ||
      !user.account.id ||
      !config ||
      !config.version
    )
      return
    const lastStatement = user.summary.lastStatement
    const totalAmountDue = lastStatement.totalAmountDue
    const dueDateString = moment(new Date(lastStatement.dueDate)).format(
      "DD MMM 'YY",
    )
    const dueDateValue = lastStatement.dueDate
    const balanceAmountDue = lastStatement.balanceAmountDue
    let statements = {}
    statements[lastStatement.id] = lastStatement
    setStatements(statements)
    setCycles([lastStatement])

    // billing cycle related
    const formattedBillingCycle = getFormatedBillingCycle(
      lastStatement.fromDate,
      lastStatement.toDate,
    )
    setSelectedCycle({
      label: formattedBillingCycle,
      id: lastStatement.id,
      index: 0,
    })
    setFilteredCycle(formattedBillingCycle)

    //due date string
    setDueDate(dueDateString)

    //checks for fully paid, partially paid and overdue
    if (balanceAmountDue === 0) {
      setIsCleared(true)
      setIsClearedInCurrentCycle(true)
      setIsHighlightSectionOpen(true)
    } else {
      if (new Date() > new Date(dueDateValue)) setIsOverDue(true)
      if (balanceAmountDue !== totalAmountDue) setIsPartiallyPaid(true)
    }
  }, [user, config])

  // when summary api is called and the latest statement dates are fetched, we get the billing cycles using api
  useEffect(() => {
    // we cant continue with billing cycle api if we dont have the parameter available
    if (!user.summary.lastStatement.toDate) return
    ;(async function () {
      // formating from and to date for get billing cycles api paylod
      let toDate = new Date(user.summary.lastStatement.toDate)
      let fromDate = new Date(toDate.getTime() - 210 * 24 * 60 * 60 * 1000)
      let finalToDate = moment(user.summary.lastStatement.toDate).format(
        "YYYY-MM-DD",
      )
      let finalFromDate = moment(fromDate).format("YYYY-MM-DD")

      const statementsResponse = await AccountService.getBillingCycles({
        accountId: user?.account?.id,
        from: finalFromDate,
        to: finalToDate,
      })
      if (statementsResponse.data.success) {
        let statements = {}
        setCycles(prev => [...prev, ...statementsResponse.data.data.statements])
        statementsResponse.data.data.statements.forEach(statement => {
          statements[statement.id] = statement
        })

        //all the statements of the user is cached in a variable, with a key value pair of statementID and statement data
        setStatements(prev => {
          return { ...prev, ...statements }
        })
      }
    })()
  }, [])

  useEffect(() => {
    if (!currentStatement) return
    ;(async function () {
      // fetching 3 transactions for the statement summary page.
      setIsTransactionsLoading(true)
      try {
        const transactionsResponse = await AccountService.getTransactions(
          user.account.id,
          {
            count: 3,
            offset: 0,
            from: moment(currentStatement.fromDate).format("YYYY-MM-DD"),
            to: moment(currentStatement.toDate).format("YYYY-MM-DD"),
          },
        )
        if (transactionsResponse.data.success) {
          setTransactions(transactionsResponse.data.data.transactions)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsTransactionsLoading(false)
      }
    })()
  }, [currentStatement])

  const handleDateSelectClick = () => {
    setIsDateBottomSheetOpen(true)
  }

  const handleDateBottomSheetClose = () => {
    setIsDateBottomSheetOpen(false)
  }

  const handleDateSelect = (label, id, index) => {
    setSelectedCycle({ label: label, id: id, index: index })
  }

  const handleApplyFilterClick = () => {
    setFilteredCycle(selectedCycle.label)
    handleDateBottomSheetClose()
  }

  const handleStatementHighlightSection = () => {
    setIsHighlightSectionOpen(prev => !prev)
  }

  const handleDownloadStatementClick = () => {
    setDownloadStatementBottomSheetOpen(true)
  }
  const handleDownloadStatementBottomSheetClose = () => {
    setDownloadStatementBottomSheetOpen(false)
  }

  const handleViewLatestBill = () => {
    // set the cycle variables back to the latest statement data
    const formatedBillingCycle = getFormatedBillingCycle(
      user.summary.lastStatement.fromDate,
      user.summary.lastStatement.toDate,
    )
    setSelectedCycle({
      label: formatedBillingCycle,
      id: user.summary.lastStatement.id,
      index: 0,
    })
    setFilteredCycle(formatedBillingCycle)
  }

  // to run when the apply filter is clicked, from date bottomsheet
  useEffect(() => {
    if (!filteredCycle) return

    //check if the selected cycle is past or current
    if (selectedCycle?.index !== 0) {
      setIsPastCycle(true)
    } else setIsPastCycle(false)

    const selectedBillingCycleStatement = statements[selectedCycle.id]
    setCurrentStatement(selectedBillingCycleStatement)
    const totalAmountDue = selectedBillingCycleStatement.totalAmountDue
    const dueDateValue = selectedBillingCycleStatement.dueDate
    const dueDateString = moment(
      new Date(selectedBillingCycleStatement.dueDate),
    ).format("DD MMM 'YY")
    const balanceAmountDue = selectedBillingCycleStatement.balanceAmountDue
    // due date related states
    setDueDate(dueDateString)
    //only checks for cleared and partial paid because we need more data from backend regarding payment of bill
    if (balanceAmountDue === 0) {
      setIsCleared(true)
      setIsOverDue(false)
      setIsPartiallyPaid(false)
      if (selectedCycle?.index === 0) setIsClearedInCurrentCycle(true)
    } else {
      // a check for current cycle status, if the amount is paid in current cycle which was due previously then set the isCleared flag
      if (selectedCycle?.index !== 0) {
        //make sure we are not in current cycle
        setIsCleared(isClearedInCurrentCycle)
      } else setIsCleared(false) // if we are inn current cycle and still the balance amt is > 0 then its not cleared
      if (balanceAmountDue !== totalAmountDue) {
        // check if it is partially paid
        setIsPartiallyPaid(true)
        setIsOverDue(false)
      } else if (new Date() > new Date(dueDateValue)) {
        // if not partially check for over due
        setIsOverDue(true)
        setIsPartiallyPaid(false)
      } else {
        setIsOverDue(false)
        setIsPartiallyPaid(false)
      }
    }
  }, [filteredCycle])

  const renderAmountToBePaid = () => {
    //if it is past cycle and only if it is partially paid we show remaining amount otherwise the total amount due is shown in the center
    if (isPastCycle) {
      if (isPartiallyPaid) {
        return (
          <div className='v3-widget-remaininig-amount-due'>
            <div className='v3-widget-remaining-amount-due-text'>
              {t("statements.remainingAmount")}
            </div>
            <div className='v3-widget-remaining-amount-due-data'>
              {formatAmount(currentStatement?.balanceAmountDue)}
            </div>
          </div>
        )
      } else return null
    } else {
      // if in the current cycle the bill is not cleared and either it is not paritially paid or over-due we show the minimum amount due to be paid
      if ((!isCleared && !isPartiallyPaid) || (!isCleared && isOverDue)) {
        return (
          <div className='v3-widget-minimum-amount-due'>
            <div className='v3-widget-minimum-amount-due-text'>
              {t("statements.minimumAmount")}
            </div>
            <div className='v3-widget-minimum-amount-due-data'>
              {formatAmount(currentStatement?.minimumAmountDue)}
            </div>
          </div>
        )
      } else if (isPartiallyPaid) {
        // if it is partially paid then remaining amount is shown
        return (
          <div className='v3-widget-remaininig-amount-due'>
            <div className='v3-widget-remaining-amount-due-text'>
              {t("statements.remainingAmount")}
            </div>
            <div className='v3-widget-remaining-amount-due-data'>
              {formatAmount(currentStatement?.balanceAmountDue)}
            </div>
          </div>
        )
      } else return null
    }
  }

  const handlePayNowClick = () => {
    if (screen.home.repayments.repaymentType !== RepaymentTypes.S2S_PG) {
      window.location.href = session.repaymentUrl
    } else setIsPaymentGatewayOpen(true)
  }

  const handleClosePaymentGatewayBottomsheet = () => {
    setIsPaymentGatewayOpen(false)
  }

  return (
    <>
      <div className='v3-statement-summary-container'>
        <Header
          text={"Statement Summary"}
          onBack={() => window.history.go(-1)}
        />
        {!isANewUser ? (
          <>
            <div className='v3-statement-billing-display-section'>
              <div className='v3-statement-billing-left-section'>
                <div className='v3-statement-billing-cycle-logo'>
                  <img src={statementSummary} alt='statement summary logo' />
                </div>
                <div className='v3-statement-billing-data'>
                  <div className='v3-statement-billing-data-text'>
                    {t("statements.billingCycleText")}
                  </div>
                  <div className='v3-statement-billing-date-interval'>
                    <span className='v3-billing-cycle-date-month'>
                      {filteredCycle?.split("-")[0]?.split("'")[0]}
                    </span>
                    '{filteredCycle?.split("-")[0]?.split("'")[1]} -{" "}
                    <span className='v3-billing-cycle-date-month'>
                      {filteredCycle?.split("-")[1]?.split("'")[0]}
                    </span>
                    '{filteredCycle?.split("-")[1]?.split("'")[1]}
                  </div>
                </div>
              </div>
              <div
                className='v3-statement-billing-calender'
                onClick={handleDateSelectClick}
              >
                <img
                  src={statementSummaryDateFilter}
                  alt='statment summary date filter'
                />
              </div>
            </div>
            <div className='v3-statement-summary-mid-section'>
              <div className='v3-statement-summary-mid-section-semi-background' />
              <div className='v3-statement-summary-widget-section'>
                <div
                  className='v3-statement-summary-widget-top-section'
                  style={{
                    justifyContent:
                      ((isCleared && !isPartiallyPaid) ||
                        (isPastCycle && !isPartiallyPaid)) &&
                      "center",
                  }}
                >
                  <div
                    className='v3-widget-total-amount-due'
                    style={{
                      textAlign:
                        (isCleared || (isPastCycle && !isPartiallyPaid)) &&
                        "center",
                    }}
                  >
                    <div className='v3-widget-total-amount-due-text'>
                      {isCleared ? "Total Amount" : "Total Amount Due"}
                    </div>
                    {/* Check for the past cylcle is not added for the due amount text color , need extra data from backend */}
                    <div
                      className='v3-widget-total-amount-due-data'
                      style={{
                        color:
                          isOverDue && theme.v3.cssVars.tertiaryNegative.color1,
                      }}
                    >
                      {isCleared || isPartiallyPaid
                        ? formatAmount(currentStatement?.totalAmountDue)
                        : formatAmount(currentStatement?.balanceAmountDue)}
                    </div>
                  </div>
                  {renderAmountToBePaid()}
                </div>
                {/* statement highlights section hidden */}
                <div
                  style={{ border: "none" }}
                  className='v3-statement-summary-widget-mid-section'
                >
                  {/* <div className='v3-statement-summary-highlights-seciton'>
                    <div className='v3-statement-summary-highlights-left-section'>
                      <div className='v3-highlights-logo'>
                        <img src={coins} alt='coings image' />
                      </div>
                      <div className='v3-highlights-text'>
                        Statement highlights
                      </div>
                    </div>
                    <div
                      className='v3-statement-highlights-arrow-down'
                      onClick={handleStatementHighlightSection}
                    >
                      <ArrowDown size={"9.5px"} />
                    </div>
                  </div> */}
                  {/* {isHighlightSectionOpen && ( */}
                  {/* <div className='v3-statement-summary-highlights-dropdown-section'>
                      <div className='v3-statement-summary-highlights-spends-tab'>
                        <div className='v3-spends-tab-label'>Spends</div>
                        <div className='v3-spends-tab-value'>
                          {formatAmount(currentStatement?.financeCharges)}
                        </div>
                      </div>
                      <div className='v3-statement-summary-highlights-emis-tab'>
                        <div className='v3-emis-tab-label'>
                          EMIs
                          <div className='v3-emis-tab-count'>3</div>
                        </div>
                        <div className='v3-emis-tab-value'>
                          {formatAmount(currentStatement?.emi)}
                        </div>
                      </div>
                      <div className='v3-statement-summary-highlights-fees-tab'>
                        <div className='v3-fees-tab-label'>
                          Fees
                          <div className='v3-fees-tab-count'>2</div>
                        </div>
                        <div className='v3-fees-tab-value'>
                          {formatAmount(currentStatement?.fees)}
                        </div>
                      </div>

                      <div
                        className='v3-statement-summary-highlights-download-statement'
                        onClick={handleDownloadStatementClick}
                      >
                        Download Statement{" "}
                        <span>
                          <img src={download} alt='download image' />
                        </span>
                      </div> 
                    </div> */}
                  {/* )} */}
                </div>
                {isCleared ? (
                  <div className='v3-statement-summary-widget-all-clear-section'>
                    {currentStatement?.balanceAmountDue === 0 && (
                      <div className='v3-statement-summary-widget-all-clear-text'>
                        {t("statements.fullyPaidText")}
                      </div>
                    )}
                    {isPastCycle && isClearedInCurrentCycle && (
                      <div className='v3-statement-summary-widget-all-clear-text'>
                        {isPartiallyPaid
                          ? "Remaining due amount was carried to next bill"
                          : isOverDue
                            ? "Due amount was carried to next billing cycle"
                            : null}
                      </div>
                    )}
                    <div className='v3-statement-summary-widget-all-clear-greetings'>
                      {isPastCycle &&
                      isClearedInCurrentCycle &&
                      currentStatement?.balanceAmountDue > 0
                        ? t("statements.statementSummaryClearNow")
                        : t("statements.statementSummaryClear")}
                    </div>
                  </div>
                ) : !isPastCycle ? (
                  <div className='v3-statement-summary-widget-bottom-section'>
                    <div className='v3-statement-summary-due-date'>
                      <div className='v3-statement-summary-due-date-text'>
                        {isOverDue ? "Overdue on" : "Due on"}
                      </div>
                      <div
                        className='v3-statement-summary-due-date-data'
                        style={{
                          color:
                            isOverDue &&
                            theme.v3.cssVars.tertiaryNegative.color1,
                        }}
                      >
                        {dueDate}
                      </div>
                    </div>
                    {isRepaymentFlowEnabled && (
                      <div
                        className='v3-statement-summary-widget-cta-button'
                        style={{
                          backgroundColor:
                            isOverDue &&
                            theme.v3.cssVars.tertiaryNegative.color1,
                        }}
                        onClick={handlePayNowClick}
                      >
                        {t("statements.payNowButton")}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='v3-statement-summary-widget-past-cycle-pending'>
                    <div className='v3-statement-summary-widget-past-cycle-pending-text'>
                      {isOverDue
                        ? isClearedInCurrentCycle
                          ? t("statements.pastDueAmountNextBillinngCycle")
                          : t("statements.dueAmountNextBillingCycle")
                        : isPartiallyPaid
                          ? isClearedInCurrentCycle
                            ? t(
                                "statements.pastRemainingDueAmountNextBillingCycle",
                              )
                            : t("statements.remainingDueAmountNextBillingCycle")
                          : null}
                    </div>
                    <div className='v3-statement-summary-widget-past-cycle-pending-warning-text'>
                      <span className='v3-statement-summary-widget-past-cycle-warning-icon'>
                        ⚠️
                      </span>
                      {t("statements.yetToBePaidText")}
                    </div>
                    <div
                      className='v3-statement-summary-widget-view-latest-bill'
                      onClick={handleViewLatestBill}
                    >
                      {t("statements.viewBill")}{" "}
                      <span>
                        <img src={arrowRight} />
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* nudge section hidden */}
              {false && (
                <div
                  className='v3-statement-summary-nudge-section'
                  style={{
                    top: isHighlightSectionOpen && "268px",
                    backgroundColor:
                      isOverDue &&
                      !isCleared &&
                      theme.v3.cssVars.tertiaryNegative.color3,
                  }}
                >
                  {/* <div className='v3-statement-summary-nudge'>
                    {false && ( // flow of these nudges are not yet finalised so kept it hardcoded
                      <>
                        <div className='v3-statement-summary-reward-nudge'>
                          <div className='v3-nudge-left-section'>
                            <div className='v3-nudge-logo'>
                              <img
                                src={rewardPointIcon}
                                width={"20px"}
                                height={"20px"}
                                alt='reward nudge logo'
                              />
                            </div>
                            <div className='v3-reward-nudge-text'>5500</div>
                            <div className='v3-nudge-reward-text'>
                              reward points
                            </div>
                          </div>
                          <div className='v3-nudge-right-chevron'>
                            <ArrowRight size={"9px"} color={"#1965CF"} />
                          </div>
                        </div>
                        <div className='v3-statement-summary-cashback-nudge'>
                          <div className='v3-nudge-left-section'>
                            <div className='v3-nudge-logo'>
                              <img
                                src={cashbackIcon}
                                width={"20px"}
                                height={"20px"}
                                alt='reward nudge logo'
                              />
                            </div>
                            <div className='v3-cashback-nudge-text'>₹5500</div>
                            <div className='v3-nudge-cashback-text'>
                              cashbacks
                            </div>
                          </div>
                          <div className='v3-nudge-right-chevron'>
                            <ArrowRight size={"9px"} color={"#1965CF"} />
                          </div>
                        </div>
                      </>
                    )}
                    {false && ( // flow of these nudges are not yet finalised so kept it hardcoded
                      <div className='v3-statement-summary-reward-cashback-section'>
                        {true && ( // flow of these nudges are not yet finalised so kept it hardcoded
                          <div className='v3-statement-summary-reward-only-nudge-section'>
                            <div className='v3-statement-summary-reward-only-nudge'>
                              <div className='v3-nudge-left-section'>
                                <div className='v3-nudge-reward-text'>
                                  You have earned
                                </div>
                                <div className='v3-nudge-logo'>
                                  <img
                                    src={rewardPointIcon}
                                    width={"20px"}
                                    height={"20px"}
                                    alt='reward nudge logo'
                                  />
                                </div>
                                <div className='v3-reward-only-nudge-text'>
                                  5500
                                </div>
                                <div className='v3-nudge-reward-text'>
                                  reward points this cycle
                                </div>
                              </div>
                              <div className='v3-nudge-right-chevron'>
                                <ArrowRight size={"9px"} color={"#1965CF"} />
                              </div>
                            </div>
                          </div>
                        )}
                        {false && (
                          <div className='v3-statement-summary-cashback-only-nudge'>
                            <div className='v3-nudge-left-section'>
                              <div className='v3-nudge-reward-text'>
                                You have earned
                              </div>
                              <div className='v3-nudge-logo'>
                                <img
                                  src={cashbackIcon}
                                  width={"20px"}
                                  height={"20px"}
                                  alt='reward nudge logo'
                                />
                              </div>
                              <div className='v3-cashback-only-nudge-text'>
                                ₹5500
                              </div>
                              <div className='v3-nudge-cashback-text'>
                                cashbacks this cycle
                              </div>
                            </div>
                            <div className='v3-nudge-right-chevron'>
                              <ArrowRight size={"9px"} color={"#1965CF"} />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div> */}
                </div>
              )}
            </div>
            <StatementTransaction_v3
              from={moment(currentStatement?.fromDate).format("YYYY-MM-DD")}
              to={moment(currentStatement?.toDate).format("YYYY-MM-DD")}
              selectedCycle={selectedCycle}
              transactionHeader={"Recent Transactions"}
              transactionsData={transaction}
              isViewAllTransactionEnabled={true}
              isHighlightSectionOpen={isHighlightSectionOpen}
              isTransactionsAvailable={transaction?.length !== 0}
              isTransactionsLoading={isTransactionsLoading}
            />
          </>
        ) : (
          <>
            <div className='v3-statement-new-user-section'>
              <div className='v3-statement-new-user-image-container'>
                <img
                  src={emptyStatementBg}
                  className='v3-statement-new-user-image-bg'
                  alt='new user bg'
                />
                <img src={newUserStatementV3} alt='new user ' />
              </div>
              <div className='v3-statement-new-user-text-section'>
                <div className='v3-statement-new-user-header-text'>
                  {t("statements.newUserHeaderText")}
                </div>
                <div className='v3-statement-new-user-description-text'>
                  {t("statements.newUserHeaderDescription")}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <BottomSheet
        open={isDateBottomSheetOpen}
        onDismiss={handleDateBottomSheetClose}
      >
        <div className='v3-statement-summary-bottom-sheet-section'>
          <div className='v3-summary-bottom-sheet-header'>
            <div className='v3-summary-bottom-sheet-header-text'>
              {t("statements.chooseBillingCycle")}
            </div>
            <div
              className='v3-summary-bottom-sheet-close-icon'
              onClick={handleDateBottomSheetClose}
            >
              {/* <Cross color='#0E0E0E' /> */}
              <img src={crossIcon} />
            </div>
          </div>
          <div className='v3-summary-bottom-sheet-body'>
            {cycles?.map((cycle, index) => {
              const fromDate = cycle.fromDate
              const toDate = cycle.toDate
              const formattedBillingCycle = getFormatedBillingCycle(
                fromDate,
                toDate,
              )
              const statementId = cycle.id
              const isSelected = formattedBillingCycle === selectedCycle?.label

              return (
                <div
                  className={`v3-summary-bottom-sheet-body-date-container ${
                    isSelected ? "selected-cycle" : "not-selected-cycle"
                  }`}
                  onClick={() =>
                    handleDateSelect(formattedBillingCycle, statementId, index)
                  }
                >
                  <div
                    className={`v3-summary-bottom-sheet-body-date-value ${
                      isSelected ? "selected-date" : "not-selected-date"
                    }`}
                  >
                    {formattedBillingCycle} {index === 0 && "(latest)"}
                  </div>
                  {isSelected && (
                    <div className='v3-summary-bottom-sheet-body-date-selected-icon'>
                      <CheckIcon
                        color={theme.v3.cssVars.secondary.color1}
                        width={"25.5px"}
                        height={"21.5px"}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
          <div className='v3-summary-bottom-sheet-cta-button'>
            <ColorButton
              color={"#FFFFFF"}
              width={"100%"}
              text={"Apply Filters"}
              isDisable={false}
              onPress={handleApplyFilterClick}
            />
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={isDownloadStatementBottomSheetOpen}
        onDismiss={handleDownloadStatementBottomSheetClose}
      >
        {/* PASSING HARDCODED VALUE (APIS FOR DOWNLOAD STATEMENT IS NOT AVAILABLE) */}
        <DownloadStatement
          downloadStatementStatus={"INIT"}
          isDownloadSelected={false}
          emailSentStatus={"INIT"}
          handleDownloadStatementBottomSheetClose={
            handleDownloadStatementBottomSheetClose
          }
        />
      </BottomSheet>
      <PaymentGatewayV3
        isOpen={isPaymentGatewayOpen}
        onClose={handleClosePaymentGatewayBottomsheet}
      />
    </>
  )
}

export default StatementSummary
