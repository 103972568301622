import { Issuers } from "../../utils/enums"
import {
  SET_ACCOUNT,
  SET_ACTIVATION_CODE,
  SET_CARDS,
  SET_CARD_REPLACEMENT_FEE,
  SET_CASHBACK,
  SET_CUSTOMER,
  SET_EMI_DETAILS,
  SET_REWARD,
  SET_REWARD_SUMMARY,
  SET_SPEND_CATEGORY,
  SET_SPEND_GROUP,
  SET_SUMMARY,
  SET_USER,
  SET_SPEND_GROUPS,
  ADD_FAILED_EMI_TRANSACTION,
} from "../ActionTypes"
import store from "../Store"

export const setUser = data => dispatch => {
  const session = store.getState().session
  if (session?.issuer === Issuers.AU_BANK) {
    if (data?.account?.status) {
      data.account.status = "ACTIVE"
    }
  }
  if (session?.issuer === Issuers.FEDERAL_BANK) {
    // For Federal ACTIVE is equal  TO DORMANT.
    if (data?.account?.status === "DORMANT") {
      data.account.status = "ACTIVE"
    }
  }
  if (session?.issuer === Issuers.FEDERAL_BANK) {
    // For Federal ACTIVE is equal  TO DORMANT.
    if (data?.account?.status === "DORMANT") {
      data.account.status = "ACTIVE"
    }
  }
  dispatch({
    type: SET_USER,
    payload: data,
  })
}

export const setCustomer = data => dispatch => {
  dispatch({
    type: SET_CUSTOMER,
    payload: data,
  })
}

export const setAccount = data => dispatch => {
  const session = store.getState().session
  if (session?.issuer === Issuers.AU_BANK) {
    if (data?.account?.status) {
      data.account.status = "ACTIVE"
    }
  }
  if (session?.issuer === Issuers.FEDERAL_BANK) {
    // For Federal ACTIVE is equal  TO DORMANT.
    if (data?.account?.status === "DORMANT") {
      data.account.status = "ACTIVE"
    }
  }
  if (session?.issuer === Issuers.FEDERAL_BANK) {
    // For Federal ACTIVE is equal  TO DORMANT.
    if (data?.account?.status === "DORMANT") {
      data.account.status = "ACTIVE"
    }
  }
  dispatch({
    type: SET_ACCOUNT,
    payload: data,
  })
}

export const setCards = data => dispatch => {
  dispatch({
    type: SET_CARDS,
    payload: data,
  })
}

export const setEmiDetails = data => dispatch => {
  dispatch({
    type: SET_EMI_DETAILS,
    payload: data,
  })
}

export const setSummary = data => dispatch => {
  dispatch({
    type: SET_SUMMARY,
    payload: data,
  })
}
export const setRewardPoints = data => dispatch => {
  dispatch({
    type: SET_REWARD,
    payload: data,
  })
}

export const setCashback = data => dispatch => {
  dispatch({
    type: SET_CASHBACK,
    payload: data,
  })
}

export const setAggregateRewardsSummary = data => dispatch => {
  dispatch({
    type: SET_REWARD_SUMMARY,
    payload: data,
  })
}
export const setSpendGroup = data => dispatch => {
  dispatch({
    type: SET_SPEND_GROUP,
    payload: data,
  })
}

export const setSpendCategory = data => dispatch => {
  dispatch({
    type: SET_SPEND_CATEGORY,
    payload: data,
  })
}

export const setReplacementFee = data => dispatch => {
  dispatch({
    type: SET_CARD_REPLACEMENT_FEE,
    payload: data,
  })
}

export const setactivationCodeFromURL = data => dispatch => {
  dispatch({
    type: SET_ACTIVATION_CODE,
    payload: data,
  })
}

export const addFailedEmiTransaction = transactionId => dispatch => {
  dispatch({
    type: ADD_FAILED_EMI_TRANSACTION,
    payload: transactionId,
  })
}
