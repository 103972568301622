import moment from "moment"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../../../utils/constants"
import {
  captureEvents,
  consoleError,
  formatAmount,
  getTransactionDate,
} from "../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import TransactionLogo from "../../../core/transactionLogo"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"

import { EventName, PwaVersions } from "../../../../utils/enums"
import ConvertEmiLoader from "../../../core/SkeletonLoader/convertEmiLoader"
import { Checkbox } from "../../../../core"
import ChevronLeftIcon from "../../../../nativeBaseReplacements/ChevronLeftIcon"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { Radio } from "../../../../nativeBaseReplacements"
import { addFailedEmiTransaction } from "../../../../store/actions/User"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

// This is EMI implementation (v1 and v2) and not related to pwa v1 version.
function EMIPlans_v1() {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const { isEmiPlansSet, txnState, emiPlansData } = common
  const [value, setValue] = useState()
  const [emiPlans, setEmiPlans] = useState([])
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(false)
  const failedEmiTransactions = user?.failedEmiTransactions
  const isConvertToEMIDisabled = failedEmiTransactions.includes(txnState?.txnId)

  useEffect(() => {
    let data = {}
    captureEvents({ eventName: EventName.CONVERT_TO_EMI, metadata: data })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!isEmiPlansSet || location?.state?.txnId != txnState?.txnId) {
        dispatch(clearCommonState())
        dispatch(setCommonState({ txnState: location.state }))

        await handleApiAuth({
          apiId: ApiIds.EMI_CONFIG,
          onAuthSuccess: onEmiConfigAuthSuccess(location.state.amount),
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("EmiElement.emiConfigReason"),
          mpinReason: t("EmiElement.emiConfigReason"),
          toast,
          navigate,
        })
      } else {
        let emi_plans = emiPlansData.emiPlans
        emi_plans.sort(sortByTenure)
        setEmiPlans(emi_plans)
        setValue(emiPlansData.emiPlans[0]["tenureInMonths"])
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmiPlansSet])

  function sortByTenure(a, b) {
    if (a.tenureInMonths < b.tenureInMonths) {
      return 1
    }
    if (a.tenureInMonths > b.tenureInMonths) {
      return -1
    }
    return 0
  }

  const onEmiConfigAuthSuccess = amount => async apiToken => {
    try {
      const response = await AccountService.getEMIConfig(
        apiToken,
        user.account.id,
        amount,
        location.state.txnId,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isEmiPlansSet: true,
            emiPlansData: result.data,
          }),
        )

        navigate("/ConvertToEMI", { replace: true })
      } else {
        consoleError(result?.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(t("EmiElement.emiTxnConvertedErrorToast"))
        } else {
          toast(t("EmiElement.errorToastMesg"))
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("EmiElement.errorToastMesg"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onCreateEmiAuthSuccess = async apiToken => {
    try {
      const data = {
        tenureInMonths: value,
        txnRefId: txnState?.txnId,
      }
      const response = await AccountService.createEMI(
        apiToken,
        user.account.id,
        data,
      )
      let metadata = {
        tenureInMonths: value,
      }
      captureEvents({
        eventName: EventName.CONVERT_TO_EMI_REQUESTED,
        metadata: metadata,
      })
      const result = response?.data
      if (result?.success) {
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_SUCCESS,
          metadata: {},
        })
        if (txnState?.txnType === "AUTHORIZE") {
          toast(t("EmiElement.emiConversionReqToastMesg"))
        } else {
          toast(t("EmiElement.conversionSuccessToastMesg"))
        }
        navigate("/", { replace: true })
      } else {
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_ERROR,
          metadata: {},
        })
        consoleError(result.errors)
        navigate("/", { replace: true })
        handleCreateEmiFailure(result?.errors)
      }
    } catch (error) {
      captureEvents({ eventName: EventName.CONVERT_TO_EMI_ERROR, metadata: {} })
      consoleError(error)
      toast(t("EmiElement.convertToEmiErrorToast"))
      navigate("/", { replace: true })
    }
  }

  const handleCreateEmiFailure = errors => {
    const errorReason = errors?.reason
    if (
      errorReason?.includes("No txn record found for txn") ||
      errorReason?.includes("Amos key not present for txn")
    ) {
      dispatch(addFailedEmiTransaction(location?.state?.txnId))
      toast(t("CardDetailsWidgetV3.problemInEmiConversion"))
    } else {
      if (EMI_CONVERTED_ERROR_STRING.test(errors)) {
        toast(t("EmiElement.emiTxnConvertedErrorToast"))
      } else {
        captureEvents({
          eventName: EventName.CONVERT_TO_EMI_ERROR,
          metadata: {},
        })
        toast(t("EmiElement.convertToEmiErrorToast"))
      }
    }
  }

  const handleConvertToEMI = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.CREATE_EMI,
      onAuthSuccess: onCreateEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: t("EmiElement.emiCreateReason"),
      mpinReason: t("EmiElement.emiCreateReason"),
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }
  const getEmiDuration = tenure => {
    let start = moment().format("MMM ‘YY")
    let end = moment()
      .add(tenure - 1, "month")
      .format("MMM ‘YY")
    console.log(start, end)
    return start + " - " + end
  }

  return (
    <div
      style={
        getVersionedDisplay
          ? {
              minHeight: windowDim.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }
          : {}
      }
      className='emiPlans-v1'
    >
      {error ? (
        <ErrorScreen />
      ) : loading ? (
        <div>
          <ConvertEmiLoader />
        </div>
      ) : (
        <div className='emiPlans-main-div-v1'>
          <div
            className='emiPlans-header-div-v1'
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          >
            {getVersionedDisplay() ? (
              <Header
                text='Convert to EMI'
                onBack={async () => {
                  window.history.go(-1)
                }}
              />
            ) : (
              <div
                className='emiPlans-header-v1'
                style={{
                  backgroundColor: getVersionedDisplay()
                    ? theme.headerBackgroundColor
                    : null,
                }}
              >
                <div className='emiPlans-header-leftIcon-v1'>
                  <Link
                    style={{
                      textDecoration: "None",
                    }}
                    to={txnState?.prevPath}
                  >
                    <ChevronLeftIcon
                      size='24px'
                      color={
                        getVersionedDisplay() ? theme.titleColor : "#303030"
                      }
                      width='fit-content'
                    />
                  </Link>
                </div>
                <div className='emiPlans-header-text-div-v1'>
                  <div
                    className='emiPlans-header-text-v1'
                    style={{
                      color: getVersionedDisplay()
                        ? theme.titleColor
                        : "#303030",
                      fontFamily: theme.fontFamily,
                    }}
                  >
                    {t("EmiElement.convertToEmiHeader")}
                  </div>
                </div>
              </div>
            )}

            <div
              className='emiPlans-cardInterface-v1'
              {...styles.cardInterface}
            >
              {txnState?.logo ? (
                <img
                  {...styles.image}
                  src={`data:image/jpg;base64,${txnState?.logo}`}
                  alt='logo'
                  width={TRANSACTION_LOGO_WIDTH + "px"}
                  height={TRANSACTION_LOGO_HEIGHT + "px"}
                />
              ) : (
                <div className='emiPlans-tax-transactionLogo-v1'>
                  <TransactionLogo transactionType={txnState?.txnType} />
                </div>
              )}
            </div>
            <div
              className='emiPlans-transaction-title-div-v1'
              alignItems={"center"}
              mt='4px'
            >
              <div
                className='emiPlans-transaction-title-text-v1'
                style={{
                  color: getVersionedDisplay() ? theme.appTextColor : null,
                  fontFamily: theme.fontFamily,
                }}
              >
                {txnState?.description}
              </div>
            </div>
            <div className='emiPlans-tax-date-text-div-v1'>
              <div
                className='emiPlans-tax-date-text-v1'
                style={{
                  color: getVersionedDisplay() ? theme.appTextColor : null,
                  fontFamily: theme.fontFamily,
                }}
              >
                {getTransactionDate(
                  txnState?.transactionDate,
                  txnState?.postingDate,
                )}
              </div>
            </div>
            <div
              className='emiPlans-tax-amount-text-div-v1'
              alignItems={"center"}
              mt='8px'
            >
              <div
                className='emiPlans-tax-amount-text-div-v1'
                {...styles.displayAmount}
                style={{
                  color: getVersionedDisplay() ? theme.appTextColor : null,
                  fontFamily: theme.fontFamily,
                }}
              >
                {formatAmount(txnState?.amount)}
              </div>
            </div>
          </div>
          <div className='emiPlans-choose-plan-div'>
            <div className='emiPlans-choose-plan-div-1'>
              <div className='emiPlans-choose-plan-text-div'>
                <div
                  className='emiPlans-choose-plan-text'
                  style={{
                    color: getVersionedDisplay() ? theme.appTextColor : null,
                  }}
                >
                  {t("EmiElement.choosePlanCTA")}
                </div>
              </div>
              {emiPlans &&
                emiPlans.map((emiPlan, index) => (
                  <div
                    className='emiPlans-map-div'
                    key={index}
                    style={{
                      margin: "5px",
                      marginLeft: "0px",
                      background: getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : "#F8F8F8",
                      borderBottom: "1px solid #ECECEC",
                    }}
                  >
                    <div>
                      <div
                        className='emiPlans-monthlyPlanTextBox'
                        style={{
                          backgroundColor: getVersionedDisplay()
                            ? `${theme.backgroundColor}60`
                            : "#FFE5A6",
                        }}
                      >
                        <div
                          className='emiPlans-monthlyPlanText'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          {" "}
                          {`${emiPlan.tenureInMonths} months`}
                        </div>
                      </div>
                      <div className='emiPlans-amountPerMonth-div'>
                        <div
                          className='emiPlans-amountPerMonth-text'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          {`₹${emiPlan.emi}`}
                          <span style={{ fontSize: "14px" }}>
                            {t("EmiElement.perMonth")}
                          </span>
                        </div>
                      </div>
                      <div
                        className='emiPlans-monthlyPlanIntrestBox-div'
                        {...styles.monthlyPlanIntrestBox}
                      >
                        <div
                          className='emiPlans-monthlyPlanIntrestBox-div-text'
                          {...styles.monthlyPlanIntrest}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          {t("EmiElement.interest")}{" "}
                          {`${formatAmount(
                            emiPlan.emi * emiPlan.tenureInMonths -
                              txnState?.amount,
                          )}`}
                        </div>
                        <div
                          className='emiPlans-monthlyPlanIntrest-text-1'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          .
                        </div>
                        <div
                          className='emiPlans-monthlyPlanIntrest-text-2'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          {t("EmiElement.processingFees")}{" "}
                          {`${formatAmount(emiPlan.processingFees)}`}
                        </div>
                      </div>
                      <div className='emiPlans-monthlyPlanDuration-div'>
                        <div
                          className='emiPlans-monthlyPlanDuration-text'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          {t("EmiElement.duration")}{" "}
                          {getEmiDuration(emiPlan?.tenureInMonths)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={() => {
                          setValue(emiPlan.tenureInMonths)
                        }}
                        color={theme.color1}
                        name='myRadioGroup'
                        accessibilityLabel='pick'
                        style={{ width: "20px" }}
                      >
                        <Radio
                          color={theme.color1}
                          isChecked={value === emiPlan.tenureInMonths}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className='emiPlans-tnc-box'>
              <div
                className='emiPlans-tnc-div-v1'
                style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
              >
                {screen?.emi?.isTncEnabled && (
                  <div
                    className='setMpin-tnc'
                    style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
                  >
                    <div
                      className='setMpin-tnc-container'
                      style={{
                        width: "100%",
                        backgroundColor:
                          config?.version === PwaVersions.V2 ||
                          config?.version === PwaVersions.V2_1
                            ? theme.widgetBackgroundColor
                            : "#FFFFFF",
                      }}
                    >
                      <div
                        onClick={() => setIsTncChecked(!isTncChecked)}
                        className='setMpin-tnc-checkbox'
                      >
                        <Checkbox
                          marginLeft='-3px'
                          {...styles.tncCheckBox}
                          className={
                            isTncChecked
                              ? "setMpin-tnc-checkBox-checked"
                              : "setMpin-tnc-checkBox-notchecked"
                          }
                          checkmarkClassName={
                            isTncChecked
                              ? "setMpin-tnc-checkMark-checked"
                              : "setMpin-tnc-checkMark-notchecked"
                          }
                          isChecked={isTncChecked}
                          border={"1px solid blue"}
                          style={{
                            backgroundColor: isTncChecked ? theme.color1 : null,
                          }}
                        />
                      </div>
                      <div
                        className='setMpin-tnc-text'
                        style={{ color: theme.appTextColor }}
                      >
                        <EmbeddedLink text={screen?.emi?.tncUrl} />
                      </div>
                    </div>
                  </div>
                )}
                {!isConvertToEMIDisabled && (
                  <ColorButton
                    style={{ width: "100%", borderRadius: "6px" }}
                    text='Convert to EMI'
                    isLoading={submitLoading}
                    onPress={() => handleConvertToEMI()}
                    isDisable={
                      (screen?.emi?.isTncEnabled && !isTncChecked) || !value
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EMIPlans_v1
