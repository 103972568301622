import React, { useEffect, useState, useContext, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Header } from "../../core"
import { useLocation, useNavigate } from "react-router-dom"
import Cross from "../../svg/v3/Cross"
import ArrowDown from "../../svg/arrowDown"
import AllTransactions_v3 from "../../core/AllTransactions_v3"
import AccountService from "../../../services/AccountService"
import InfiniteScroll from "../../core/InfiniteScrollV3"
import {
  getFormatedBillingCycle,
  getMonthForIndex,
  resetErrorTryAgainCount,
} from "../../../utils/functions"
import {
  customDateRangeFilterFields,
  EventName,
  HomeTabs,
  rewardRedirectionType,
} from "../../../utils/enums"
import Calender from "../../svg/v3/Calender"
import { BottomSheet } from "react-spring-bottom-sheet"
import FilterByDate from "./filterByDateV3"
import moment from "moment"
import {
  FilterBottomSheetTabs,
  transactionFilterLables,
  transactionFilterTypes,
} from "../../../utils/constants"
import { ErrorType } from "../../../utils/constants"
import { ErrorContext } from "../../auth/ErrorScreenContext"

const initialAvailableFiltersList = [
  {
    renderIcon: color => <Calender color={color} />,
    hasDropDown: true,
    label: transactionFilterLables.CYCLE,
    id: "date",
  },
  {
    renderIcon: null,
    hasDropDown: false,
    label: transactionFilterLables.SPENDS,
    id: "Spends",
  },
  {
    renderIcon: null,
    hasDropDown: false,
    label: transactionFilterLables.REFUNDS,
    id: "Refunds",
  },
  {
    renderIcon: null,
    hasDropDown: false,
    label: transactionFilterLables.CASHBACK,
    id: "Cashback",
  },
]

const ShowTransactions_v3 = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const dispatch = useDispatch()
  const { setErrorState } = useContext(ErrorContext)
  const { state } = useLocation()
  const naviate = useNavigate()
  const [filterList, setFilterList] = useState([])
  const [transactions, setTransactions] = useState([])
  const [billedTransactions, setBilledTransactions] = useState([])
  const [isTransactionsFetched, setIsTransactionsFetched] = useState(false)
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const [billedTransactionFilters, setBilledTransactionFilters] = useState({
    minimumTransactionAmount: null,
    maximumTransactionAmount: null,
    txnType: null,
    merchantCategoryCode: null,
    txnNature: null,
  })
  const redirectionTypeV3 = screen?.home?.reward?.redirectionTypesV3
  const noChangeItems = [
    rewardRedirectionType.URL,
    rewardRedirectionType.HF_CASHBACK,
    rewardRedirectionType.SSO,
  ]
  const isANewUser = Object.keys(user.summary.lastStatement).length === 0

  const AvailableFiltersList = useMemo(() => {
    if (redirectionTypeV3.some(item => noChangeItems.includes(item))) {
      return initialAvailableFiltersList
    }

    if (
      redirectionTypeV3.every(item =>
        [
          rewardRedirectionType.LR_TRANSACTIONS,
          rewardRedirectionType.HF_REWARDS,
        ].includes(item),
      )
    ) {
      return initialAvailableFiltersList.filter(
        filter => filter.id !== "Cashback",
      )
    }

    return initialAvailableFiltersList
  }, [redirectionTypeV3, initialAvailableFiltersList])

  const [billingCycles, setBillingCycles] = useState([
    isANewUser
      ? {
          from: moment(user.account.dateCreated).isBefore(
            moment().subtract(89, "days"),
          )
            ? moment().subtract(89, "days").format("YYYY-MM-DD")
            : moment(user.account.dateCreated).format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        }
      : {
          fromDate: moment(
            moment(user.summary.lastStatement.toDate).format("YYYY-MM-DD"),
          )
            .add(1, "days")
            .format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
        },
  ])

  const [selectedCycle, setSelectedCycle] = useState(
    isANewUser
      ? {
          from: moment(user.account.dateCreated).isBefore(
            moment().subtract(89, "days"),
          )
            ? moment().subtract(89, "days").format("YYYY-MM-DD")
            : moment(user.account.dateCreated).format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
          label: getFormatedBillingCycle(
            moment(user.account.dateCreated).isBefore(
              moment().subtract(89, "days"),
            )
              ? moment().subtract(89, "days").format("YYYY-MM-DD")
              : moment(user.account.dateCreated).format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD"),
          ),
        }
      : {
          from: moment(
            moment(user.summary.lastStatement.toDate).format("YYYY-MM-DD"),
          )
            .add(1, "days")
            .format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
          label: getFormatedBillingCycle(
            moment(
              moment(user.summary.lastStatement.toDate).format("YYYY-MM-DD"),
            )
              .add(1, "days")
              .format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD"),
          ),
        },
  )
  const [filteredCycle, setFilteredCycle] = useState(
    isANewUser
      ? {
          from: moment(user.account.dateCreated).isBefore(
            moment().subtract(89, "days"),
          )
            ? moment().subtract(89, "days").format("YYYY-MM-DD")
            : moment(user.account.dateCreated).format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
          label: getFormatedBillingCycle(
            moment(user.account.dateCreated).isBefore(
              moment().subtract(89, "days"),
            )
              ? moment().subtract(89, "days").format("YYYY-MM-DD")
              : moment(user.account.dateCreated).format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD"),
          ),
        }
      : !state?.from && !state?.to
        ? {
            from: moment(
              moment(user.summary.lastStatement.toDate).format("YYYY-MM-DD"),
            )
              .add(1, "days")
              .format("YYYY-MM-DD"),
            to: moment(new Date()).format("YYYY-MM-DD"),
            label: getFormatedBillingCycle(
              moment(
                moment(user.summary.lastStatement.toDate).format("YYYY-MM-DD"),
              )
                .add(1, "days")
                .format("YYYY-MM-DD"),
              moment(new Date()).format("YYYY-MM-DD"),
            ),
          }
        : {
            from: moment(state?.from).format("YYYY-MM-DD"),
            to: moment(state?.to).format("YYYY-MM-DD"),
            label: getFormatedBillingCycle(
              moment(state?.from).format("YYYY-MM-DD"),
              moment(state?.to).format("YYYY-MM-DD"),
            ),
          },
  )

  const [filterClick, setFilterClick] = useState(false)
  const [customDateRange, setCustomDateRange] = useState({
    from: null,
    to: null,
  })
  const [availableFilters, setAvailableFilters] = useState(
    state?.previousScreen === EventName.HOME &&
      moment(filteredCycle?.from)
        .startOf("day")
        .isAfter(moment(user.summary.lastStatement.toDate).startOf("day"))
      ? [AvailableFiltersList[0]]
      : AvailableFiltersList,
  )
  const [isUnbilledCycle, setIsUnbilledCycle] = useState(
    state?.previousScreen === EventName.HOME &&
      moment(filteredCycle?.from)
        .startOf("day")
        .isAfter(moment(user.summary.lastStatement.toDate).startOf("day")),
  )
  const [isCustomDateRangeSelected, setIsCustomDateRangeSelected] =
    useState(false)
  const [invalidCustomDateRange, setInvalidCustomDateRange] = useState({
    invalidRange: false,
    durationExceeded: false,
    invalidField: null,
  })
  const [dateType, setDateType] = useState(FilterBottomSheetTabs.CYCLE)

  useEffect(() => {
    getBillingCycles()
  }, [])

  useEffect(() => {
    setAvailableFilters(
      state?.previousScreen === EventName.HOME &&
        moment(filteredCycle?.from)
          .startOf("day")
          .isAfter(moment(user.summary.lastStatement.toDate).startOf("day"))
        ? [AvailableFiltersList[0]]
        : AvailableFiltersList,
    )

    let fromDate = moment(filteredCycle?.from).startOf("day")
    let toDate = moment(user.summary.lastStatement.toDate).startOf("day")

    if (fromDate.isAfter(toDate)) {
      setIsUnbilledCycle(true)
    } else {
      setIsUnbilledCycle(false)
    }
  }, [filteredCycle])

  const handleDateSelect = (label, from, to) => {
    setInvalidCustomDateRange({
      invalidRange: false,
      durationExceeded: false,
      invalidField: null,
    })
    setCustomDateRange({
      from: null,
      to: null,
    })
    if (label === "Custom Range") setIsCustomDateRangeSelected(true)
    else setIsCustomDateRangeSelected(false)
    setSelectedCycle({
      label: label,
      from: moment(from).format("YYYY-MM-DD"),
      to: moment(to).format("YYYY-MM-DD"),
    })
  }

  const handleApplyFilter = () => {
    if (!(isANewUser && dateType === FilterBottomSheetTabs.CYCLE)) {
      setBilledTransactions([])
    }

    state.from = null
    state.to = null

    if (
      isCustomDateRangeSelected &&
      customDateRange.from?.length > 0 &&
      customDateRange.to?.length > 0
    ) {
      const differenceInDays = moment(customDateRange.to, "DD/MM/YYYY").diff(
        moment(customDateRange.from, "DD/MM/YYYY"),
        "days",
      )

      if (differenceInDays > 90) {
        setInvalidCustomDateRange({
          invalidRange: false,
          durationExceeded: true,
          invalidField: null,
        })
        return
      }

      if (
        !moment(customDateRange.from, "DD/MM/YYYY", true).isValid() ||
        customDateRange.from?.length !== 10 ||
        moment(customDateRange.from, "DD/MM/YYYY").isAfter(
          new Date(),
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.FROM,
        })
        return
      }

      if (
        !moment(customDateRange.to, "DD/MM/YYYY", true).isValid() ||
        customDateRange.to?.length !== 10 ||
        moment(customDateRange.to, "DD/MM/YYYY").isBefore(
          user.account.dateCreated,
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.TO,
        })
        return
      }

      if (differenceInDays < 0) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.BOTH,
        })
        return
      }

      setFilteredCycle({
        label: "Custom Range",
        from: moment(customDateRange.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
        to: moment(customDateRange.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    } else {
      setFilteredCycle(selectedCycle)
    }

    setIsBottomSheetOpen(false)
  }

  const getBillingCycles = async () => {
    const lastStatementToDate = user.summary.lastStatement.toDate
    if (!lastStatementToDate) return
    try {
      let toDate = new Date(lastStatementToDate)
      let fromDate = new Date(toDate.getTime() - 210 * 24 * 60 * 60 * 1000)
      let finalToDate = moment(lastStatementToDate).format("YYYY-MM-DD")
      let finalFromDate = moment(fromDate).format("YYYY-MM-DD")

      const statementsResponse = await AccountService.getBillingCycles({
        accountId: user?.account?.id,
        from: finalFromDate,
        to: finalToDate,
      })
      if (statementsResponse.data.success) {
        setBillingCycles(prev => [
          ...prev,
          ...statementsResponse.data.data.statements,
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toggleFilterSelect = filter => {
    if (filter?.label === transactionFilterLables.CYCLE) {
      setIsBottomSheetOpen(true)
    } else if (filter?.label === transactionFilterLables.REFUNDS) {
      setFilterClick(true)
      setBilledTransactions([])
      let currentTransactionTypes = billedTransactionFilters.txnType
        ? [...billedTransactionFilters.txnType]
        : []
      if (currentTransactionTypes.includes(transactionFilterTypes.REFUND)) {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: currentTransactionTypes.filter(
            type => type !== transactionFilterTypes.REFUND,
          ),
        }))
      } else {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: [...currentTransactionTypes, transactionFilterTypes.REFUND],
        }))
      }
    } else if (filter?.label === transactionFilterLables.SPENDS) {
      setFilterClick(true)
      setBilledTransactions([])
      let currentTransactionTypes = billedTransactionFilters.txnType
        ? [...billedTransactionFilters.txnType]
        : []
      if (currentTransactionTypes.includes(transactionFilterTypes.PURCHASE)) {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: currentTransactionTypes.filter(
            type => type !== transactionFilterTypes.PURCHASE,
          ),
        }))
      } else {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: [
            ...currentTransactionTypes,
            transactionFilterTypes.PURCHASE,
          ],
        }))
      }
    } else if (filter?.label === transactionFilterLables.CASHBACK) {
      setFilterClick(true)
      setBilledTransactions([])
      let currentTransactionTypes = billedTransactionFilters.txnType
        ? [...billedTransactionFilters.txnType]
        : []
      if (currentTransactionTypes.includes(transactionFilterTypes.CASHBACK)) {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: currentTransactionTypes.filter(
            type => type !== transactionFilterTypes.CASHBACK,
          ),
        }))
      } else {
        setBilledTransactionFilters(prevState => ({
          ...prevState,
          txnType: [
            ...currentTransactionTypes,
            transactionFilterTypes.CASHBACK,
          ],
        }))
      }
    }

    if (filter?.label !== transactionFilterLables.CYCLE) {
      if (filterList.includes(filter?.label)) {
        setFilterList(prev => {
          return prev?.filter(label => label !== filter?.label)
        })
      } else {
        setFilterList(prev => [...prev, filter?.label])
      }
    }
  }

  const formatedFromDate =
    moment(filteredCycle?.from).format("DD MMM YYYY") ||
    filteredCycle?.label?.split("-")[0].substring(0, 6)
  const formatedToDate =
    moment(filteredCycle?.to).format("DD MMM YYYY") ||
    filteredCycle?.label?.split("-")[1].substring(0, 7)

  const fetchTransactions = async (count, offset) => {
    const accountId = user?.account?.id
    const from = state?.from || filteredCycle?.from
    const to = state?.to || filteredCycle?.to
    try {
      const response = await AccountService.getTransactions(user?.account?.id, {
        count,
        offset,
        from: from,
        to: to,
        minimumTransactionAmount:
          billedTransactionFilters?.minimumTransactionAmount,
        maximumTransactionAmount:
          billedTransactionFilters?.maximumTransactionAmount,
        txnType: billedTransactionFilters?.txnType,
        merchantCategoryCode: billedTransactionFilters?.merchantCategoryCode,
        txnNature: billedTransactionFilters?.txnNature,
      })
      const status = response.status

      if (!response.data.success) {
        setErrorState(response?.status, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
        throw new Error("Api error, couldn't fetch transactions")
      }

      resetErrorTryAgainCount()

      if (status === 200) {
        if (billedTransactions.length === 0) {
          setBilledTransactions(prevItems => [
            ...response.data.data.transactions,
          ])
        } else {
          const currentTransactions = billedTransactions
          setBilledTransactions([
            ...currentTransactions,
            ...response.data.data.transactions,
          ])
        }
        setIsTransactionsFetched(true)
      }
      const hasMoreFlag = response?.data?.data?.hasMore
      return { status, hasMoreFlag }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
      } else {
        console.error(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
      }
    }
  }

  useEffect(() => {
    if (billedTransactions?.length === 0) {
      setAvailableFilters([availableFilters[0]])
    } else {
      setAvailableFilters(AvailableFiltersList)
    }
  }, [isTransactionsFetched])

  const fetchUnbilledTransactions = async (count, offset) => {
    const accountId = user?.account?.id
    try {
      const response = await AccountService.getUnbilledTransactions(accountId, {
        count,
        offset,
      })
      const status = response.status

      if (!response.data.success) {
        setErrorState(response?.status, () =>
          fetchUnbilledTransactions(accountId, count, offset),
        )
        return
      }

      resetErrorTryAgainCount()

      if (status === 200) {
        if (transactions.length === 0) {
          setTransactions(prevItems => [...response.data.data.transactions])
        } else {
          const currentTransactions = transactions
          setTransactions([
            ...currentTransactions,
            ...response.data.data.transactions,
          ])
        }
      }
      const hasMoreFlag = response?.data?.data?.hasMore
      return { status, hasMoreFlag }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          fetchUnbilledTransactions(accountId, count, offset),
        )
      } else {
        console.log(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          fetchUnbilledTransactions(accountId, count, offset),
        )
      }
    }
  }

  return (
    <>
      <div className='v3-all-transactions-container'>
        <Header
          text={"Transaction History"}
          onBack={() => naviate("/", { state: { prevScreen: HomeTabs.CARD } })}
        />
        {/* {state.previousScreen !== EventName.HOME && (
          <> */}
        <div className='v3-filters-section'>
          <div className='v3-filters-container'>
            {availableFilters?.map(data => {
              return !filterList?.includes(data?.label) ? (
                <div
                  className='v3-filters-card'
                  onClick={() => toggleFilterSelect(data)}
                >
                  {data?.renderIcon ? data?.renderIcon() : data?.id}
                  {data?.hasDropDown && (
                    <div className='v3-filter-arrow'>
                      <ArrowDown
                        color={theme.v3.rawColors.tertiaryNeutral.color1}
                        size={"8px"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className='v3-filters-card active'
                  onClick={() => toggleFilterSelect(data)}
                >
                  {data?.renderIcon
                    ? data?.renderIcon(theme.v3.cssVars.secondary.color1)
                    : data?.id}
                  {data?.renderIcon ? (
                    <div className='v3-filter-arrow'>
                      <ArrowDown
                        color={theme.v3.rawColors.secondary.color1}
                        size={"8px"}
                      />
                    </div>
                  ) : (
                    <div className='v3-filters-card-cross'>
                      <Cross
                        height={"14"}
                        width={"14"}
                        color={theme.v3.rawColors.secondary.color1}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        {filteredCycle && (
          <div className='v3-all-transactions-cycle-section'>
            Showing results from{" "}
            <span className='v3-all-transaction-cycle-date'>
              {moment(formatedFromDate).format("Do MMM 'YY")} to{" "}
              {moment(formatedToDate).format("Do MMM 'YY")}
            </span>
          </div>
        )}

        <InfiniteScroll
          dataLength={11}
          next={
            state?.previousScreen === EventName.HOME &&
            moment(filteredCycle?.from)
              .startOf("day")
              .isAfter(
                moment(user.summary.lastStatement.toDate).startOf("day"),
              ) &&
            dateType === FilterBottomSheetTabs.CYCLE
              ? fetchUnbilledTransactions
              : fetchTransactions
          }
          list={
            state?.previousScreen === EventName.HOME &&
            moment(filteredCycle?.from)
              .startOf("day")
              .isAfter(
                moment(user.summary.lastStatement.toDate).startOf("day"),
              ) &&
            dateType === FilterBottomSheetTabs.CYCLE
              ? transactions
              : billedTransactions
          }
          setList={
            state?.previousScreen === EventName.HOME &&
            moment(filteredCycle?.from)
              .startOf("day")
              .isAfter(
                moment(user.summary.lastStatement.toDate).startOf("day"),
              ) &&
            dateType === FilterBottomSheetTabs.CYCLE
              ? setTransactions
              : setBilledTransactions
          }
          loadingDelay={100}
          displayElement={data => <AllTransactions_v3 transactions={data} />}
          hasPullDownToRefresh={false}
          hasScrollTopTopOption={false}
          selectedFilter={filterList}
          fromDate={filteredCycle?.from || state?.from}
          toDate={filteredCycle?.to || state?.to}
        />
      </div>
      <BottomSheet
        open={isBottomSheetOpen}
        onDismiss={() => setIsBottomSheetOpen(false)}
      >
        <FilterByDate
          billingCycles={billingCycles}
          selectedCycle={selectedCycle}
          setCustomDateRange={setCustomDateRange}
          customDateRange={customDateRange}
          handleDateSelect={handleDateSelect}
          handleApplyFilter={handleApplyFilter}
          setIsBottomSheetOpen={setIsBottomSheetOpen}
          invalidCustomDateRange={invalidCustomDateRange}
          setInvalidCustomDateRange={setInvalidCustomDateRange}
          setDateType={setDateType}
          dateType={dateType}
        />
      </BottomSheet>
    </>
  )
}

export default ShowTransactions_v3
