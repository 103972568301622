const TokenisationDefaultMerchant = ({ color }) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill={color || "#1965CF"} />
      <path
        opacity='0.4'
        d='M28 19.34V26C28 28 27 29 25 29H22.33C22.164 29 22.03 28.866 22.03 28.7V25.622C22.03 24.625 21.3549 23.695 20.3719 23.529C19.1229 23.319 18.03 24.288 18.03 25.5V28.7C18.03 28.866 17.896 29 17.73 29H15C13 29 12 28 12 26V19.34C12.11 19.43 12.23 19.52 12.36 19.59C12.81 19.85 13.34 20 13.9 20C15.23 20 16.54 19.17 16.95 18C17.36 19.17 18.67 20 20 20C21.33 20 22.64 19.17 23.05 18C23.46 19.17 24.77 20 26.1 20C26.66 20 27.19 19.85 27.64 19.59C27.77 19.52 27.89 19.43 28 19.34Z'
        fill='white'
      />
      <path
        d='M15.5052 11C13.0052 11 12.6222 11.82 12.2102 13.038L11.1703 16.111C10.7323 17.405 11.1752 18.902 12.3692 19.592C12.8182 19.852 13.3433 20 13.9043 20C15.2343 20 16.5432 19.17 16.9592 18C17.3652 19.17 18.6742 20 20.0042 20C21.3342 20 22.6432 19.17 23.0492 18C23.4652 19.17 24.7752 20 26.1042 20C26.6662 20 27.1903 19.852 27.6393 19.592C28.8333 18.902 29.2762 17.405 28.8382 16.111L27.7982 13.038C27.3862 11.82 27.0033 11 24.5033 11H15.5052Z'
        fill='white'
      />
    </svg>
  )
}
export default TokenisationDefaultMerchant
